import type { Pathname } from "react-router-dom";
import {
  DASHBOARD_ROUTE,
  QUESTION_ITEM_ROUTE,
} from "@/shared/routes/authRoutes.utils";
import Dashboard from "@/pages/Dashboard";
import Question from "@/pages/Question";

type RoutesData = Array<{
  path: Pathname;
  Component: React.FC;
}>;
export const publicRoutes: RoutesData = [
  {
    path: QUESTION_ITEM_ROUTE,
    Component: Question,
  },
  {
    path: DASHBOARD_ROUTE,
    Component: Dashboard,
  },
];
