/* eslint-disable object-curly-newline */
import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import UI from "@/shared/UI";
import styles from "./index.module.scss";
import QuestionsService from "@/entities/questions/services/questions.service";
import { HOME_ROUTE } from "@/shared/routes/authRoutes.utils";
import { formattingTheCreatedAt } from "@/shared/helpers";
import type {
  QuestionStatusType,
  QuestionsWithPagination,
} from "@/entities/questions/types";

const limit = 10;

const QuestionsTableTest: React.FC = () => {
  const [statusMode, setStatusMode] = React.useState<QuestionStatusType>();
  const [currentPageInt, setCurrentPageInt] = React.useState<number>(1);
  const [data, setData] = React.useState<QuestionsWithPagination>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string>("");

  React.useEffect(() => {
    const req = QuestionsService.getAll({
      page: currentPageInt,
      limit,
      status: statusMode,
    });
    req.then((res) => {
      setData(res);
      setIsLoading(false);
    });
    req.catch((res) => setError(res));
  }, [currentPageInt, statusMode]);

  const setCurrentPageHandler = ({ selected }: { selected: number }) => {
    setCurrentPageInt(selected + 1);
  };

  const onChangeStatus = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (statusMode !== e.currentTarget.innerText) {
      setCurrentPageInt(1);
      setStatusMode(e.currentTarget.innerText as QuestionStatusType);
      setIsLoading(true);
    }
  };

  const onResetStatus = () => {
    setStatusMode("" as QuestionStatusType);
  };

  return (
    <div className={styles.root}>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.table}>
            <div className={styles.grid}>
              <UI.Button variant="filled" className={styles.th}>
                Email
              </UI.Button>
              <UI.Button variant="filled" className={styles.th}>
                Host
              </UI.Button>
              <UI.Button variant="filled" className={styles.th}>
                Description
              </UI.Button>
              <UI.Button variant="filled" className={styles.th}>
                Date
              </UI.Button>
            </div>
            {error && !isLoading && <div>error: {JSON.stringify(error)}</div>}
            {isLoading && !error ? (
              <div className={styles.loaderWrapper}>
                <UI.Loader />
              </div>
            ) : (
              <div className={styles.tbody}>
                <div className={styles.filter}>
                  <button
                    onClick={onResetStatus}
                    className={clsx(styles.filterBtn, styles.resetStatusBtn)}
                  >
                    all
                  </button>
                  <button
                    onClick={onChangeStatus}
                    className={clsx(styles.filterBtn, styles.pending)}
                  >
                    pending
                  </button>
                  <button
                    onClick={onChangeStatus}
                    className={clsx(styles.filterBtn, styles.issue)}
                  >
                    issue
                  </button>
                  <button
                    onClick={onChangeStatus}
                    className={clsx(styles.filterBtn, styles.resolved)}
                  >
                    resolved
                  </button>
                </div>
                {data?.questions.map(
                  ({ id, email, link, description, createdAt, status }) => (
                    <Link
                      key={createdAt}
                      className={clsx(styles.grid, styles.tr, styles[status])}
                      to={`${HOME_ROUTE}${id}`}
                    >
                      <div className={clsx(styles.td, "truncation")}>
                        {email}
                      </div>
                      <div className={clsx(styles.td, "truncation")}>
                        {link}
                      </div>
                      <div className={clsx(styles.td, "truncation")}>
                        {description}
                      </div>
                      <div className={styles.td}>
                        {formattingTheCreatedAt(createdAt)}
                      </div>
                    </Link>
                  )
                )}
              </div>
            )}
          </div>
          {data && (
            <ReactPaginate
              breakLabel="..."
              nextLabel={false}
              onPageChange={setCurrentPageHandler}
              pageRangeDisplayed={3}
              pageCount={data?.pages}
              previousLabel={false}
              renderOnZeroPageCount={null}
              className={styles.pagination}
              activeClassName={styles.paginationItemActive}
              pageLinkClassName={styles.paginationItemLink}
              pageClassName={styles.paginationItem}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionsTableTest;
