import axios, { AxiosResponse } from "axios";
import $api from "@/shared/http";
import type { ISigninResponse, SigninInput, UserInfo } from "../types";
import { API_REFRESH_URL, API_SIGNIN_URL } from "@/shared/http/urls";

export default class AuthService {
  static async signin({
    login,
    password,
  }: SigninInput): Promise<AxiosResponse<ISigninResponse>> {
    return $api.post<ISigninResponse>(API_SIGNIN_URL, {
      login,
      password,
    });
  }

  static async checkAuth(): Promise<UserInfo> {
    const res = await axios.get<ISigninResponse>(API_REFRESH_URL, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
      },
    });

    if (!res.data.active) {
      throw new Error("Ваш аккаунт заблокирован.");
    }

    if (res.data.accessToken && res.data.refreshToken) {
      localStorage.setItem("accessToken", res.data.accessToken);
      localStorage.setItem("refreshToken", res.data.refreshToken);
    }

    return {
      id: res.data.id,
      active: res.data.active,
      login: res.data.login,
    };
  }
}
