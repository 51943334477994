/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import clsx from "clsx";

import styles from "./index.module.scss";

interface IUITextareaProps {
  name?: string;
  value: string;
  className?: string;
  onBlur?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const UITextarea: React.FC<IUITextareaProps> = ({
  name,
  value,
  className,
  onBlur,
  onChange,
}) => {
  const txRef = React.useRef<HTMLTextAreaElement>(null);

  React.useEffect(() => {
    if (txRef.current) {
      txRef.current.style.height = `${txRef.current.scrollHeight}px`;
      txRef.current.style.overflowY = "hidden";
    }
  }, [txRef]);

  return (
    <div className={clsx(styles.root, className)}>
      <textarea
        name={name}
        ref={txRef}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onInput={() => {
          if (txRef.current) {
            txRef.current.style.height = "auto";
            txRef.current.style.height = `${txRef.current.scrollHeight}px`;
          }
        }}
        className={clsx(styles.textarea)}
      />
    </div>
  );
};

export default UITextarea;
