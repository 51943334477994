export const formattingTheCreatedAt = (str: string): string => {
  const result = str.replaceAll("-", ".").match(/^\d{0,4}.\d{0,2}.\d{0,2}/);
  if (result?.[0]) {
    const yy = result[0].slice(0, 4);
    const mm = result[0].slice(5, 7);
    const dd = result[0].slice(8, 10);
    return `${dd}.${mm}.${yy}`;
  }
  return str;
};
