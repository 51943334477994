import * as yup from "yup";

export const signinValidationSchema = yup.object().shape({
  login: yup
    .string()
    .required("Поле является обязательным")
    .min(4, "Не менее 4 символов")
    .max(64, "Не более 64 символов"),
  password: yup.string().required("Поле является обязательным"),
  // .min(6, "Не менее 6 символов"),
});
