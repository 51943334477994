import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { storeShallowHOC } from "@/shared/store/helpers/storeShallowHOC";
import type { UserInfo } from "../types";

export type QuestionsStore = {
  isAuth: boolean;
  userInfo: UserInfo;
  updateIsAuth: (newState: boolean) => void;
  updateUserInfo: (newState: UserInfo) => void;
};

const store = create<QuestionsStore>()(
  immer((set) => ({
    isAuth: false,
    userInfo: {} as UserInfo,
    updateIsAuth: (newState) => {
      set((state) => void (state.isAuth = newState));
    },
    updateUserInfo: (newState) => {
      set((state) => void (state.userInfo = newState));
    },
  }))
);

export const useAuthStore = storeShallowHOC(store);
