/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable object-curly-newline */
import React from "react";
import clsx from "clsx";
import { useQuery } from "@tanstack/react-query";
import QuestionsService from "@/entities/questions/services/questions.service";
import QuestionDescription from "@/features/Question/QuestionDescriptions";
import QuestionTechInfo from "@/features/Question/QuestionTechInfo";
import UI from "@/shared/UI";

import styles from "./index.module.scss";

const Question: React.FC = () => {
  const [activePageIndex, setActivePageIndex] = React.useState<number>(0);

  const pageIcons: Array<{ icon: React.ReactElement<SVGElement> }> = [
    {
      icon: (
        <svg width="35" height="21" viewBox="0 0 35 21" fill="none">
          <circle cx="2.5" cy="2.5" r="2.5" fill="#444444" />
          <circle cx="2.5" cy="10.5" r="2.5" fill="#444444" />
          <circle cx="2.5" cy="18.5" r="2.5" fill="#444444" />
          <rect x="8" width="27" height="5" rx="2.5" fill="#444444" />
          <path
            d="M8 10.5C8 9.11929 9.11929 8 10.5 8H32.5C33.8807 8 35 9.11929 35 10.5C35 11.8807 33.8807 13 32.5 13H10.5C9.11929 13 8 11.8807 8 10.5Z"
            fill="#444444"
          />
          <rect x="8" y="16" width="27" height="5" rx="2.5" fill="#444444" />
        </svg>
      ),
    },
    {
      icon: (
        <svg width="26" height="25" viewBox="0 0 26 25" fill="none">
          <path
            d="M25.8574 20.9771L20.1779 15.4059C19.9878 15.2194 19.9878 14.917 20.1779 14.7306C20.368 14.5441 20.368 14.2417 20.1779 14.0553L18.4568 12.367C18.2667 12.1805 17.9585 12.1805 17.7684 12.367L16.5637 13.5488C16.3736 13.7353 16.0654 13.7353 15.8753 13.5488L7.09783 4.93863L5.37676 2.74389L2.62306 0.886798L1.41832 2.06858L2.96728 4.43215L5.54887 6.45807L14.3263 15.0682C14.5164 15.2547 14.5164 15.557 14.3263 15.7435L13.1216 16.9253C12.9314 17.1118 12.9314 17.4141 13.1216 17.6006L14.8426 19.2889C15.0327 19.4754 15.3409 19.4754 15.531 19.2889C15.7211 19.1024 16.0294 19.1024 16.2195 19.2889L21.899 24.8601C22.0891 25.0466 22.3973 25.0466 22.5874 24.8601L25.8574 21.6524C26.0475 21.466 26.0475 21.1636 25.8574 20.9771Z"
            fill="#444444"
          />
          <path
            d="M17.5977 2.95552L19.9197 0.633474C20.7802 -0.210639 18.0265 -0.548287 15.617 1.8153C14.9286 2.43433 13.9305 3.94255 14.7566 6.37365L12.8821 8.19894L16.152 11.4065L18.0265 9.5812C20.5048 10.3916 22.0424 9.41244 22.6734 8.73714C25.0829 6.37355 24.7387 3.67237 23.8782 4.51648L21.5561 6.83852L18.2001 6.24763L17.5977 2.95552Z"
            fill="#444444"
          />
          <path
            d="M6.92353 21.1341L4.60424 23.4271C3.74373 24.2712 6.49744 24.6089 8.90695 22.2453C9.59537 21.6262 10.5935 20.118 9.76741 17.6869L12.2236 15.2307L8.95371 12.0231L6.49746 14.4794C4.01913 13.669 2.48159 14.6481 1.85054 15.3234C-0.558964 17.687 -0.214715 20.3882 0.645798 19.5441L2.96508 17.251L6.32116 17.8419L6.92353 21.1341Z"
            fill="#444444"
          />
        </svg>
      ),
    },
  ];

  const qidFromUrlParams = Number(window.location.pathname.replace("/", ""));
  const { data, error, isSuccess, isLoading } = useQuery({
    queryFn: () => QuestionsService.getOne(qidFromUrlParams),
    queryKey: [`question/${qidFromUrlParams}`],
  });

  return (
    <>
      {error && !isLoading && (
        <div className="container">{JSON.stringify(error)}</div>
      )}
      {isLoading && (
        <div className="container">
          <div className={styles.loaderWrapper}>
            <UI.Loader />
          </div>
        </div>
      )}
      {isSuccess && !isLoading && (
        <div className={styles.root}>
          <div className="container">
            <div className={styles.nav}>
              {pageIcons.map(({ icon }, i) => (
                <button
                  onClick={() => setActivePageIndex(i)}
                  className={clsx(
                    styles.navItem,
                    activePageIndex === i && styles.active
                  )}
                  key={i}
                >
                  {icon}
                </button>
              ))}
            </div>
            <div className={styles.body}>
              {activePageIndex === 0 && (
                <QuestionDescription
                  link={data.link}
                  email={data.email}
                  photo={data.photo}
                  description={data.description}
                  status={data.status}
                  comments={data.comments}
                />
              )}
              {activePageIndex === 1 && (
                <QuestionTechInfo
                  data={{
                    id: data.id,
                    link: data.link,
                    email: data.email,
                    status: data.status,
                    params: data.params,
                    createdAt: data.createdAt,
                    updatedAt: data.updatedAt,
                    user_agent: data.user_agent,
                    user_status: data.user_status,
                    description: data.description,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Question;
