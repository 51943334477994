import React from "react";
import clsx from "clsx";
import type { QuestionStatusType } from "@/entities/questions/types";
import QuestionsService from "@/entities/questions/services/questions.service";
import { useAuthStore } from "@/entities/auth/store";

import styles from "./index.module.scss";

interface IStatusSelectProps {
  className?: string;
  initialStatus: QuestionStatusType;
}

const availableSelectOptions: Array<QuestionStatusType> = ["issue", "resolved"];

const StatusSelect: React.FC<IStatusSelectProps> = ({
  className,
  initialStatus,
}) => {
  const [active, setActive] = React.useState<boolean>(false);
  const [currentValue, setCurrentValue] =
    React.useState<QuestionStatusType>(initialStatus);
  const [availableOptions, setAvailableOptions] = React.useState<
    Array<QuestionStatusType>
  >([...availableSelectOptions.filter((item) => item !== currentValue)]);

  const { userInfo } = useAuthStore((state) => state);
  const onChangeStatusHandler = (value: QuestionStatusType) => {
    const { id } = userInfo;
    QuestionsService.updateStatus({
      qid: Number(window.location.pathname.replace("/", "")),
      uid: Number(id),
      newStatus: value,
    }).then((res) => {
      setCurrentValue(res.status);
    });

    // Перезагрузка нужна для того, чтобы обновить состояние в родительском компоненте.
    // Без перезагрузки состояние родительского компонента останется устаревшим,
    // и при следующем заходе в question item состояние придет устаревшим.
    window.location.reload();
  };

  React.useEffect(() => {
    setAvailableOptions(() => [
      ...availableSelectOptions.filter((prevItem) => prevItem !== currentValue),
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  return (
    <div className={clsx(styles.root, className)}>
      <p className={styles.label}>Status</p>
      <div className={styles.select}>
        <button
          onClick={() => setActive(!active)}
          className={clsx(
            styles.viewValue,
            styles[currentValue],
            active && styles.active
          )}
        >
          <p>{currentValue}</p>
          <svg
            className={clsx(styles.arrow, active && styles.active)}
            viewBox="0 0 12 8"
            fill="none"
          >
            <path
              d="M11 7L5.73684 2L1 7"
              stroke="#444444"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </button>
        {active && (
          <div className={styles.body}>
            {availableOptions.map((item) => (
              <button
                onClick={(e) => {
                  if (
                    // eslint-disable-next-line no-restricted-globals, no-alert
                    confirm(`Сменить статус на ${e.currentTarget.innerText}?`)
                  ) {
                    setCurrentValue(
                      e.currentTarget.innerText as QuestionStatusType
                    );
                    onChangeStatusHandler(
                      e.currentTarget.innerText as QuestionStatusType
                    );
                  }
                  setActive(false);
                }}
                className={clsx(styles.status, styles[item])}
                key={item}
              >
                {item}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default StatusSelect;
