/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
import React from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import clsx from "clsx";
import AuthService from "@/entities/auth/services/auth.service.";
import { ValidationSigninType } from "./types/validation.types";
import { signinValidationSchema } from "./utils/signinValidationSchema";
import UI from "@/shared/UI";
import { useAuthStore } from "@/entities/auth/store";
import { HOME_ROUTE } from "@/shared/routes/authRoutes.utils";

import styles from "./index.module.scss";

const initialFormikValue: ValidationSigninType = {
  login: "",
  password: "",
};

const Signin: React.FC = () => {
  const navigate = useNavigate();
  const [localError, setLocalError] = React.useState<string>("");
  const { isAuth, updateUserInfo, updateIsAuth } = useAuthStore(
    (store) => store
  );

  const onSubmitHandler = async (values: ValidationSigninType) => {
    try {
      const res = await AuthService.signin(values);

      if (!res.data.active) {
        setLocalError("Ваш аккаунт заблокирован.");
      } else {
        updateUserInfo({
          id: res.data.id,
          active: res.data.active,
          login: res.data.login,
        });
        updateIsAuth(true);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        setLocalError(error.response?.data?.message);
        console.error("AxiosError", error.response?.data);
      }
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (isAuth) {
      navigate(HOME_ROUTE, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  return (
    <div className={styles.root}>
      <div className="container">
        <div className={styles.wrapper}>
          {localError || (
            <Formik
              enableReinitialize
              onSubmit={() => {
                console.log("submit");
              }}
              initialValues={initialFormikValue}
              validationSchema={signinValidationSchema}
              isInitialValid={false}
            >
              {({
                values,
                errors,
                touched,
                isValid,
                handleBlur,
                handleChange,
              }) => (
                <div className={styles.form}>
                  <h2 className={styles.title}>Авторизация</h2>
                  <div className={styles.field}>
                    <label htmlFor="login">Логин</label>
                    <input
                      type="text"
                      name="login"
                      className={clsx(
                        styles.input,
                        touched && errors.login && styles.error
                      )}
                      value={values.login}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <span className={styles.errorMessage}>
                      {touched && errors.login}
                    </span>
                  </div>
                  <div className={styles.field}>
                    <label htmlFor="password">Пароль</label>
                    <input
                      type="password"
                      name="password"
                      className={clsx(
                        styles.input,
                        touched && errors.password && styles.error
                      )}
                      value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <span className={styles.errorMessage}>
                      {touched && errors.password}
                    </span>
                  </div>
                  <UI.Button
                    disabled={!isValid}
                    onClick={() => {
                      console.log("isValid", isValid);

                      onSubmitHandler(values);
                    }}
                    className={styles.submitBtn}
                  >
                    Отправить
                  </UI.Button>
                </div>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};

export default Signin;
