import React from "react";
import clsx from "clsx";

import styles from "./index.module.scss";

interface IUILoaderProps {
  className?: string;
}

const UILoader: React.FC<IUILoaderProps> = ({ className }) => (
  <div className={clsx(styles.loader, className)} />
);

export default UILoader;
