import React from "react";

import styles from "./index.module.scss";

const Dashboard: React.FC = () => (
  <div className={styles.root}>
    <div className="container">Dashboard</div>
  </div>
);

export default Dashboard;
