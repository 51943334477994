import React from "react";
import clsx from "clsx";
import StatusSelect from "../StatusSelect";
import QuestionComments from "./components/QuestionComments";
import type {
  CommentEntity,
  QuestionStatusType,
} from "@/entities/questions/types";

import styles from "./index.module.scss";

// from QuestionEntity
interface IQuestionDescriptionProps {
  link: string;
  email: string;
  photo: string;
  status: QuestionStatusType;
  comments: Array<CommentEntity>;
  description: string;
}

const QuestionDescription: React.FC<IQuestionDescriptionProps> = ({
  link,
  email,
  photo,
  status,
  comments,
  description,
}) => {
  const [clickPhoto, setClickPhoto] = React.useState<boolean>(false);

  return (
    <div className={styles.root}>
      <div className={styles.head}>
        <div>
          <p className={clsx(styles.email, "title--lg")}>{email}</p>
          <p className={styles.link}>URL: {link}</p>
        </div>
        <StatusSelect initialStatus={status} />
      </div>
      <div>
        <div className={styles.description}>
          <p className={styles.descriptionText}>{description}</p>
        </div>
        <button
          className={clsx(styles.photo, clickPhoto && styles.active)}
          onClick={() => setClickPhoto(!clickPhoto)}
        >
          <img className={styles.photoImg} src={photo} alt="question_photo" />
          {clickPhoto && <div className={styles.photoActiveLayout} />}
        </button>
      </div>
      <QuestionComments comments={comments} />
    </div>
  );
};

export default QuestionDescription;
