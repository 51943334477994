/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import clsx from "clsx";
import type { QuestionEntity } from "@/entities/questions/types";
import StatusSelect from "../StatusSelect";

import styles from "./index.module.scss";

interface IQuestionTechInfoCopyProps {
  data: Omit<QuestionEntity, "comments" | "photo">;
}

function tryParseJSONObject(jsonString: string) {
  try {
    const o = JSON.parse(jsonString);

    if (o && typeof o === "object") {
      return o;
    }
  } catch (e) {
    /* empty */
  }

  return false;
}

const wrapsInCurlyBraces = (v: object) => {
  try {
    return (
      <>
        <span className={clsx(styles.curlyBraces, styles.open)}>&#123;</span>
        <div className={styles.object}>{parsingDataToJSXColoredCode(v)}</div>
        <span className={styles.curlyBraces}>&#125;</span>
      </>
    );
  } catch (error) {
    console.log("wrapsInCurlyBraces", error);
  }
};

const checkValueType = (v: any) => {
  try {
    const type = typeof v;
    if (typeof v === "object" && v !== null) {
      return wrapsInCurlyBraces(v);
    }
    if (typeof v === "string" && tryParseJSONObject(v)) {
      return wrapsInCurlyBraces(JSON.parse(v));
    }
    if (type === "string") {
      return (
        <span className={clsx(styles.v, styles.string)}>&#34;{v}&#34;</span>
      );
    }
    if (type === "number") {
      return <span className={clsx(styles.v, styles.number)}>{v}</span>;
    }
    if (type === "undefined" || v === null) {
      return (
        <span className={clsx(styles.v, styles.null_undefined)}>null</span>
      );
    }
  } catch (error) {
    console.log("checkType", error);
  }
};

const parsingDataToJSXColoredCode = (data: object) =>
  /* kv ==> key / value    ↴↴↴ */
  Object.entries(data).map((kv, i) => (
    /* Ключи в обьекте могут быть неуникальными.
       Так как, обьект польностью статичный, то можно использовать индекс как ключ для React.ReactElement
    */
    <div key={i}>
      <span onClick={() => console.log(kv)} className={styles.k}>
        {kv[0]}:
      </span>
      {checkValueType(kv[1])}
      {i !== Object.entries(data).length - 1 && (
        <span className={styles.comma}>&#44;</span>
      )}
    </div>
  ));

const QuestionTechInfoCopy: React.FC<IQuestionTechInfoCopyProps> = ({
  data,
}) => (
  <div className={styles.root}>
    <div className={styles.head}>
      <p className={clsx(styles.title, "title--lg")}>Technical information</p>
      <StatusSelect initialStatus={data.status} />
    </div>
    <div className={styles.json}>
      <div className={styles.curlyBraces}>&#123;</div>
      <div className={styles.body}>{parsingDataToJSXColoredCode(data)}</div>
      <div className={styles.curlyBraces}>&#125;</div>
    </div>
  </div>
);
export default QuestionTechInfoCopy;
