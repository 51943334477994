import React from "react";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import UI from "@/shared/UI";
import { DASHBOARD_ROUTE, HOME_ROUTE } from "@/shared/routes/authRoutes.utils";

import styles from "./index.module.scss";

const Header: React.FC = () => (
  <header className={styles.root}>
    <div className="container">
      <div className={styles.wrapper}>
        <NavLink to={DASHBOARD_ROUTE}>
          {({ isActive }) => (
            <UI.Button
              className={styles.btn}
              variant={!isActive ? "outlined" : "filled"}
            >
              <svg
                className={clsx(styles.btnSVG, isActive && styles.active)}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect width="11" height="9.6" fill="#444444" />
                <rect x="13" y="19.2" width="11" height="4.8" fill="#444444" />
                <rect x="13" width="11" height="17.28" fill="#444444" />
                <rect y="12.48" width="11" height="11.52" fill="#444444" />
              </svg>
              <span>Dashboard</span>
            </UI.Button>
          )}
        </NavLink>
        <NavLink to={HOME_ROUTE}>
          {({ isActive }) => (
            <UI.Button
              className={styles.btn}
              variant={!isActive ? "outlined" : "filled"}
            >
              <svg
                className={clsx(styles.btnSVG, isActive && styles.active)}
                viewBox="0 0 23 22"
                fill="none"
              >
                <rect width="23" height="4" fill="#444444" />
                <rect y="6" width="23" height="4" fill="#444444" />
                <rect y="12" width="23" height="4" fill="#444444" />
                <rect y="18" width="23" height="4" fill="#444444" />
              </svg>
              <span>Questions</span>
            </UI.Button>
          )}
        </NavLink>
      </div>
    </div>
  </header>
);

export default Header;
