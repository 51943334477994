/* eslint-disable no-return-await */
import type { AxiosResponse } from "axios";
import $api from "@/shared/http";
import type { CommentInput, ICommentEntity } from "../types";
import { API_COMMENTS_URL } from "@/shared/http/urls";

export default class CommentsService {
  static async createComment({
    qid,
    uid,
    login,
    description,
  }: CommentInput): Promise<AxiosResponse<ICommentEntity>> {
    return await $api.post<ICommentEntity>(API_COMMENTS_URL, {
      uid,
      qid,
      login,
      description,
    });
  }

  static async getAll(
    qid: number
  ): Promise<AxiosResponse<Array<ICommentEntity>>> {
    return await $api.get<Array<ICommentEntity>>(`${API_COMMENTS_URL}/${qid} `);
  }
}
