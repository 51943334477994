import $api from "@/shared/http";
import type {
  PaginationProps,
  QuestionEntity,
  QuestionsWithPagination,
  UpdateQuestionStatusInput,
  UpdateQuestionStatusResponse,
} from "../types";
import { API_QUESTIONS_URL } from "@/shared/http/urls";

export default class QuestionsService {
  static async getAll(
    { status, limit = 7, page = 1 } = {} as Partial<PaginationProps>
  ): Promise<QuestionsWithPagination> {
    let queryString = `limit=${limit}&page=${page}`;
    if (status) {
      queryString += `&status=${status}`;
    }
    return (
      await $api.get<QuestionsWithPagination>(
        `${API_QUESTIONS_URL}?${queryString}`
      )
    ).data;
  }

  static async getOne(question_id: number): Promise<QuestionEntity> {
    return (
      await $api.get<QuestionEntity>(`${API_QUESTIONS_URL}/${question_id}`)
    ).data;
  }

  static async updateStatus({
    uid,
    qid,
    newStatus,
  }: UpdateQuestionStatusInput): Promise<UpdateQuestionStatusResponse> {
    return (
      await $api.patch<UpdateQuestionStatusResponse>(
        `${API_QUESTIONS_URL}/user-status`,
        {
          uid,
          qid,
          status: newStatus,
        }
      )
    ).data;
  }
}
