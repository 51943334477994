import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Questions from "@/pages/Questions";
import MainLayout from "@/processes/layouts/MainLayout";
import { HOME_ROUTE } from "@/shared/routes/authRoutes.utils";
import { publicRoutes } from "./utils";
import { AUTH_ROUTE } from "@/shared/routes/publicRoutes.utils";
import Auth from "@/pages/Auth";

const AppRouter: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path={HOME_ROUTE} element={<MainLayout />}>
        <Route index element={<Questions />} />
        {publicRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route path={AUTH_ROUTE} element={<Auth />} />
    </Routes>
  </BrowserRouter>
);

export default AppRouter;
