/* eslint-disable function-paren-newline */
/* eslint-disable object-curly-newline */
import React from "react";
import clsx from "clsx";
import { Formik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import UI from "@/shared/UI";
import { questionCommentsValidationSchema } from "./utils/questionCommentsValidationSchema";
import type { ICommentEntity } from "@/entities/questions/types";
import CommentsService from "@/entities/questions/services/comments.service";
import { useAuthStore } from "@/entities/auth/store";
import { formattingTheCreatedAt } from "@/shared/helpers";

import styles from "./index.module.scss";

interface IQuestionCommentsProps {
  comments: Array<ICommentEntity>;
}

const initialFormikValue = {
  description: "",
};

const QuestionComments: React.FC<IQuestionCommentsProps> = ({ comments }) => {
  const client = useQueryClient();

  const { userInfo } = useAuthStore((state) => state);
  const onSubmitHandler = (description: string) => {
    const { id, login } = userInfo;
    const qidFromUrlParams = Number(window.location.pathname.replace("/", ""));
    CommentsService.createComment({
      uid: Number(id),
      qid: qidFromUrlParams,
      login,
      description,
    }).then(() =>
      client.invalidateQueries({ queryKey: [`question/${qidFromUrlParams}`] })
    );
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={() => {
        // eslint-disable-next-line no-console
        console.log("submit");
      }}
      initialValues={initialFormikValue}
      validationSchema={questionCommentsValidationSchema}
      isInitialValid={false}
    >
      {({
        values,
        errors,
        touched,
        isValid,
        handleBlur,
        handleChange,
        setValues,
      }) => (
        <>
          <p className={clsx(styles.title, "title--lg")}>Comments</p>
          <div className={styles.sendComment}>
            {touched && errors.description && (
              <span className={styles.errorMessage}>{errors.description}</span>
            )}
            <UI.Textarea
              name="description"
              className={clsx(
                styles.textarea,
                touched && errors.description && styles.error
              )}
              value={values.description}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <button
              onClick={() => {
                onSubmitHandler(values.description);
                setValues({ description: "" });
              }}
              disabled={!isValid}
              className={styles.sendCommentBtn}
            >
              <svg
                className={styles.sendCommentSVG}
                viewBox="0 0 70 70"
                fill="none"
              >
                <circle cx="35" cy="35" r="35" fill="#D9D9D9" />
                <path
                  d="M12 26C12 22.6863 14.6863 20 18 20H52C55.3137 20 58 22.6863 58 26V50H18C14.6863 50 12 47.3137 12 44V26Z"
                  fill="#6B6B6B"
                />
                <circle cx="24" cy="36" r="3" fill="#D9D9D9" />
                <circle cx="35" cy="36" r="3" fill="#D9D9D9" />
                <circle cx="46" cy="36" r="3" fill="#D9D9D9" />
              </svg>
            </button>
          </div>
          <ul className={styles.comments}>
            {comments &&
              comments
                .reverse()
                .map(({ id, login, description, createdAt }) => (
                  <li className={styles.comment} key={id}>
                    <p className={styles.commentLogin}>{login}</p>
                    <p className={styles.commentDescription}>{description}</p>
                    <p className={styles.commentCreatedAt}>
                      {formattingTheCreatedAt(createdAt)}
                    </p>
                  </li>
                ))}
          </ul>
        </>
      )}
    </Formik>
  );
};

export default QuestionComments;
