import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "@/widgets/Header";
import { useAuthStore } from "@/entities/auth/store";
import { AUTH_ROUTE } from "@/shared/routes/publicRoutes.utils";
import AuthService from "@/entities/auth/services/auth.service.";

const MainLayout: React.FC = () => {
  const { updateIsAuth, updateUserInfo } = useAuthStore((state) => state);
  const navigate = useNavigate();

  React.useEffect(() => {
    AuthService.checkAuth()
      .then((data) => {
        updateIsAuth(true);
        updateUserInfo(data);
      })
      .catch(() => {
        navigate(AUTH_ROUTE);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default MainLayout;
