import React from "react";

import clsx from "clsx";
import styles from "./index.module.scss";

type UIButtonVariants = "outlined" | "filled";
type UIButtonColors = "darkgray" | "gray";
interface IUIButtonProps {
  children: React.ReactNode;
  color?: UIButtonColors;
  variant?: UIButtonVariants;
  className?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const UIButton: React.FC<IUIButtonProps> = ({
  children,
  color = "darkgray",
  variant = "outlined",
  className,
  disabled,
  onClick,
}) => (
  <button
    disabled={disabled}
    onClick={onClick}
    className={clsx(styles.btn, className, styles[color], styles[variant])}
  >
    {children}
  </button>
);

export default UIButton;
